<template>
    <div>
        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
            <div v-if="!isLoadDetail">
                <h2 class="fw-400 font-lg d-block"><b>Rating Scale Questionnaire Submission</b></h2>
                <p>Sender : {{ submissionDetail.user.name }}</p>
            </div>
            <div v-else>
                <div class="card-body">
                    <div class="col-12 text-center py-3">
                        <p> Loading submission detail </p>
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isLoadAnswer" class="mt-3 card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4 p-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-2">
                        <h4>Questions</h4>
                    </div>
                    <div class="col-12">
                        <button v-for="(item, i) in answers" :disabled="disButton" :key="i" @click="setAnswer(i)" :class="{'btn-success' : answer == i, 'btn-outline-success' : answer != i}" class="btn w50 h50 col mr-2 mb-2 ">{{i + 1}}</button>
                    </div>
                </div>
            </div>
            <div class="row" v-for="(item, index) in answers" :key="index">
                <div class="col-12" v-show="answer == index">
                    <div class="card-body p-0">
                        <div :id="'accordion1-'+index" class="accordion mb-0">
                            <div class="card shadow-xss mb-0">
                                <div class="card-header border-radius-none bg-current" :id="'heading1-'+index">
                                    <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link text-white collapsed" data-toggle="collapse" :data-target="'#collapse1-'+index" aria-expanded="false" :aria-controls="'collapse1-'+index">Question - {{index + 1}}</button></h5>
                                </div>
                                <div :id="'collapse1-'+index" class="collapse" :aria-labelledby="'heading1'+index" :data-parent="'#accordion1-'+index">
                                    <div class="card-body text-center" v-if="item.quest.quest_type == 1">
                                        <video class="w-50 h-auto" controls>
                                            <source :src=" item.quest.path" type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="card-body" v-if="item.quest.quest_type == 2">
                                        <audio class="w-100" controls>
                                            <source :src=" item.quest.path" type="audio/mpeg">
                                        </audio>
                                    </div>
                                    <div class="card-body ql-editor">
                                        <div v-html="item.quest.question.replaceAll('[BLANK]', '[_____]')"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0" v-if="item.quest.submission_type != 7">
                        <div :id="'accordion2-'+index" class="accordion mb-0">
                            <div class="card shadow-xss mb-0">
                                <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                    <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                </div>
                                <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                    <div v-if="item.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                        <a :href=" item.answer" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>File Answer</a>
                                    </div>
                                    <div v-if="item.quest.submission_type == 2" style="width: 100wh" class="card-body ql-editor p-3 border-top bg-lightgrey">
                                        <div v-html="item.answer"></div>
                                    </div>
                                    <div v-if="item.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                        <table class="table m-0">
                                            <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                <td width="5%" v-if="opt.id == item.answer" class="bg-success text-white text-center"><i class="fas fa-check"></i></td>
                                                <td width="5%" v-else class="bg-grey text-success"></td>
                                                <td>{{opt.option}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card-body">
                <div class="col-12 text-center py-3">
                    <p> Loading answers </p>
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    filters: {
        numfor: function(x) {   
            return parseFloat(x).toFixed(2);
        }
    },
    data(){
        return {
            submissionId: this.$route.params.submissionId,
            slug: this.$route.params.idCourse,
            isLoadAnswer: true,
            isLoadDetail: true,
            submissionDetail: {},
            answers: [],
            answer: 0,
            disButton: false,
            agree: false,
            message: '',
        }
    },
    async created(){
        this.getSubmissionDetail()
        await this.getAnswers()
    },
    methods: {
        compareDatesGreater(d1, d2 = null) {
            let date1 = moment(d1).format('YYYY-MM-DD H:m');
            let date2 = moment().format('YYYY-MM-DD H:m');
            if (d2) {
                date2 = new Date(d2).getTime();
            }
            if (date1 > date2) {
                return true;
            } else {
                return false;
            }
        },
        async getSubmissionDetail(){
            this.isLoadDetail = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/submission/id?submission_id=${this.submissionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.submissionDetail = res.data.data
                    console.log('DETAIL', res.data.data)
                    this.isLoadDetail = false
                })
        },
        setItemRef(i) {
            return `upload${i}`;
        },
        async fileAction(file) {
            this.postFile(file)
        },
        setAnswer(i) {
            this.answer = i
        },
        async getAnswers(){
            this.isLoadAnswer = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/submission/answers?submission_id=${this.submissionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.answers = res.data.data
                    this.setAnswer(0)
                    this.isLoadAnswer = false
                })
        },
    }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-content{
    max-height: 50vh;
    max-width: 100%;
    overflow: auto;
}

.table {
    min-width: 100wh;
}
::v-deep .ql-editor img {
    width: 100% !important;
}

.divider {
    min-width: 100%;
    height: 1px;
    background-color: black;
    opacity: 50%;
}

.card {
    min-width: 300px
}
</style>